import React, { ComponentType, SFC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute: SFC<any> = (
    { component: Component, ...rest }:
    { component: ComponentType<any> }
    ) => {

    const isLogin = useSelector((store:any) => store.auth.isLogin);

    return(
        <Route
        {...rest}
        render={props =>
            isLogin ? (
                <Redirect to={{ pathname: "/dashboard" }} />
            ) : (
                <Component {...props} />
            )
        }
        />
    );
}

export default PublicRoute;