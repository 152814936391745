import React from 'react';
import useStyles from './EmptyContentStyle';
import { MBox } from '../../material-ui';

function EmptyContent(props:any) {
  const classes = useStyles();

  return (
    <MBox className={classes.pageWraper}>
        <img className={classes.imageEmptyContent} src="/assets/images/empty-content-image.jpg" alt="Empty Content"/>
        <MBox textAlign="center">
            <MBox fontSize={22} mt={1} className={classes.text}>{props.text?props.text:'Content not available.'}</MBox>
        </MBox>
    </MBox>
   )
}

export default EmptyContent;