import React, { useState } from 'react'
import clsx from 'clsx';
import useStyles from './TeamSelectStyles';
import MenuItem from '@material-ui/core/MenuItem';
import { MFormControl, MSelect, MInputLabel } from '../../material-ui';
import { MKeyboardArrowDownRoundedIcon } from '../../material-ui/icons';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { showAlert } from '../../store';

interface Team{
  id: number,
  name: string
}

const TEAMS_GQL=gql`
query Teams($orgId:ID!) {
  teams(orgId: $orgId) {
    id
    name
  }
}
`;

export function TeamSelect(props:any){
  const classes = useStyles({});
  const dispatch = useDispatch()
  const selectedOrgId = useSelector((store:any) => store.orgs.selectedOrgId);
  const [teams, setTeams] = useState([])
  useQuery(TEAMS_GQL, {
    variables: {orgId: selectedOrgId},
    onCompleted: (data:any) => {
      setTeams(data && data.teams)
    },
    onError: ({ graphQLErrors }:any) => {
      dispatch(showAlert({message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message && graphQLErrors[0].message, messageType:'error', showAlertMessage:true}))
    }
  })

    return(
        <div className="teamSelect">
          
            <MFormControl variant="outlined" className={clsx(classes.inputWidth, classes.teamSelect)}>
              <MInputLabel id="chooseTeam">Choose team</MInputLabel>
              <MSelect
                name="chooseTeam"
                IconComponent={MKeyboardArrowDownRoundedIcon}
                id="chooseTeam"
                color="secondary"
                className={props.redBorder && classes.redBorder}
                disabled={false}
                displayEmpty
                label="Choose team"
              >
                {/* <MenuItem value="" disabled>Choose Team *</MenuItem> */}
                {teams && teams.map((team:Team) => (
                  <MenuItem value={team.id} key={team.id}>{team.name}</MenuItem>
                ))}
              </MSelect>
            </MFormControl>
        </div>
    )
}
