import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    root: {
      display: 'flex',
    },
    content: {
        flexGrow: 1,
        marginTop: theme.spacing(8),
        backgroundColor: '#f4f5f7',
        minHeight: 'calc(100vh - 68)',
        [theme.breakpoints.down(1400)]: {
          padding: '30px 15px'
        }
    },
    appBar: {
      boxShadow: '0px 6px 10px 0px rgba(206, 209, 219, 0.35),inset 0px -1px 0px 0px rgba(224, 227, 232, 0.04)',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },   
    appBarShift: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap', 
      position: 'relative',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      overflowX: 'hidden',
      width: 15,
      overflowY: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      "& .MuiButton-contained, & form, & hr, & .scrollBar":{
        display: "none",
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 3),
      ...theme.mixins.toolbar,
    },
    hide: {
      display: 'none',
    },
    
    navigationResizer:{
      width: 28,
      height: 28,
      backgroundColor: '#fff',
      position: 'absolute',
      left: 258,
      top: 130,
      opacity: 0,
      padding: 0,
      transition: "ease .4s",
      zIndex: theme.zIndex.drawer + 1,
      border: '1px solid #ddd',
      boxShadow: "rgba(9, 30, 66, 0.05) 0px 0px 0px 1px, rgba(9, 30, 66, 0.05) 0px 2px 4px 1px",
      '&:hover':{
        backgroundColor: theme.palette.secondary.main,
        '& svg':{
          width: 24,
          height: 24,
          color: '#fff',
        }
      },
    },
    navigationResizerClosed:{
      left: 12,
    },
    arrowRight:{
      color: theme.palette.secondary.main,
    },
    arrowLeft:{
      color: theme.palette.secondary.main,
    }
  }),
);

export default useStyles;