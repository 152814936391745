import React from 'react'
import Button from '@material-ui/core/Button';
//import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     btnSmall:{
//       minWidth: 106,
//     },
//     btnMedium:{
//       minWidth: 136,
//     },
//   }),
// );

export function MButton(props:any){
    // const classes = useStyles();
    return(
        <Button {...props} disableElevation />
    )
}