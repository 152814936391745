import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logoBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingRight: theme.spacing(10),
            ...theme.mixins.toolbar,
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            '& a':{
                height: 25,
            },
          },

        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },

        mainNav:{
            listStyle: 'none',
            '& li':{
                marginRight: theme.spacing(7),
            },
            '& li:hover a':{
                color: theme.palette.secondary.main,
            },
        },
        menuLink:{
            color: theme.palette.primary.contrastText,
            textDecoration: 'none',
        },

        logoutLink:{
            "color": "#f44336",
            "&:hover":{
                color: theme.palette.error.main,
                backgroundColor: "#fddedf",
            }
        },

        userDropdown:{
            minWidth: 195,
            "& ul":{
                minWidth: 195,
                '& .MuiListItem-button':{
                    borderRadius: 4,
                    margin: theme.spacing(0,1),
                }
            },
        },
        avatarBox:{
            borderBottom: '1px solid #d8d9db',
            '& .MuiAvatar-root':{
                width: '28px',
                height: '28px',
                display: "inline-block",
            },
        },
        userAvatarWrapper:{
            '& .MuiAvatar-root':{
                width: '28px',
                height: '28px',
                display: "inline-block",
            },
        },
        headerIcon:{
            color: theme.palette.text.secondary,
        },
        dotBagde:{
            "& .MuiBadge-badge":{
                width: 10,
                height: 10,
                top: 6,
                right: 7,
                borderRadius: 25,
                color: "#ff0102",
                backgroundColor: "#ff0102",
                border: "2px solid #fff",
            },
        },

    })
);

export default useStyles;