import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme?: Theme) =>
  createStyles({
    inputWidth:{
      width: '100%',
    },
    instructions:{
      color: '#a0a5b9',
    },
    sourceLangSelect:{
      "&.MuiSelect-outlined":{
        borderColor: theme?.palette.secondary.main,
      },
    },
    redBorder:{
      "& fieldset":{
        borderColor: '#f44336 !important'
      }
    }
  }),
);

export default useStyles;