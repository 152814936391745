import { SET_AUTH, SET_CURRENT_USER_DATA } from "./AuthActionTypes";
import { RESET_STORE } from '../resetStateAction';

interface IState{
    isLogin: boolean,
    token: string,
    currentUser: {
        id: number,
        username: string,
        firstName: string,
        lastName: string,
        profilePicture: string
    }
}

interface IAction{
    type: string,
    payload: string
}

const initialState:IState = {
    isLogin: false,
    token: '',
    currentUser: {
        id: 0,
        username: '',
        firstName: '',
        lastName: '',
        profilePicture: ''
    }
}

const authReducer = (state = initialState, action:IAction) => {
    switch (action.type) {
        case SET_AUTH:
            return{
                ...state,
                isLogin: action.payload ? true:false,
                token: action.payload
            }
        case SET_CURRENT_USER_DATA:
            return{
                ...state,
                currentUser: action.payload
            }
        case RESET_STORE:
            return initialState
        default:
            return state
    }
}

export default authReducer;