import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import './index.css';

import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles';
import {IntlProvider} from "react-intl";
import {ThemeConfig} from './app/material-ui/ThemeConfig';
import {Provider} from 'react-redux';
import store from './app/store/store';
import CssBaseline from '@material-ui/core/CssBaseline';

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={ThemeConfig}>
            <IntlProvider locale="en">
            <CssBaseline/>
                <Router>
                    <App />
                </Router>
            </IntlProvider>
        </ThemeProvider>
    </Provider>
, document.getElementById('root'));

serviceWorker.unregister();
