import React, {useState} from 'react';
import clsx from 'clsx';
import useStyles from './SourceLanguageSelectStyles';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../store';
import {MKeyboardArrowDownRoundedIcon} from "../../material-ui/icons";



interface Language {
    id: number,
    name: string,
    code: string,
    // defaultLanguage: any
}

const SOURCE_LANGUAGE_GQL = gql`
    {
        sourceLanguages {
            code
            id
            name
        }
    }
`;

export function SourceLanguageSelect(props: any) {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const [sourceLanguage, setSourceLanguage] = useState([]);

    // const inlineStyle = {
    //     maxWidth: props.maxWidth, 
    //     minWidth: props.minWidth
    // };
    function findWithAttr(array: any, attr: any, value: any) {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    // console.log(props)
    const {data} = useQuery(SOURCE_LANGUAGE_GQL, {
        onCompleted: (data: any) => {
                setSourceLanguage(data && data.sourceLanguages)
        },
        onError: ({ graphQLErrors }:any) => {
          dispatch(showAlert({message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message && graphQLErrors[0].message, messageType:'error', showAlertMessage:true}))
        }
    });
    const filterSourceLanguage = () => {
        if(props.targetLanguages && props.targetLanguages.length > 0){
            let newSourceLanguages:any = data && data.sourceLanguages;
            props.targetLanguages.forEach((targetLanguageCode:any) => {
                newSourceLanguages = newSourceLanguages.filter((element:any) => element.code !== targetLanguageCode);
            });
            if(props.createProject && props.createProject == 'createProject'){
                souceLanguageCheck(newSourceLanguages);

        }else {
            setSourceLanguage(newSourceLanguages);
        }
        }else{
            if(props.capacityPerHour && props.capacityPerHour =='capacityPerHour'){
                let newSourceLanguages:any = data && data.sourceLanguages;
                souceLanguageCheck(newSourceLanguages);
            }else {
                setSourceLanguage(data && data.sourceLanguages);
            }
        }
    };
    const souceLanguageCheck = (newSourceLanguages:any)=>{
        let ary:any= [];
        let ind = findWithAttr(newSourceLanguages && newSourceLanguages, 'code', 'zh-CN');
        ary.push(newSourceLanguages && newSourceLanguages[ind]);
        let inde = findWithAttr(newSourceLanguages, 'code', 'zh-TW');
        ary.push(newSourceLanguages && newSourceLanguages[inde]);
        let index = findWithAttr(newSourceLanguages, 'code', 'zh-HK');
        ary.push(newSourceLanguages && newSourceLanguages[index]);
        let indx = findWithAttr(newSourceLanguages, 'code', 'vi-VN');
        ary.push(newSourceLanguages && newSourceLanguages[indx]);
        let indxe = findWithAttr(newSourceLanguages, 'code', 'th-TH');
        ary.push(newSourceLanguages && newSourceLanguages[indxe]);
        let indxAr = findWithAttr(newSourceLanguages, 'code', 'ar-SA');
        ary.push(newSourceLanguages && newSourceLanguages[indxAr]);
        setSourceLanguage(ary)
    };
    return (
        
        <div className="sourceLangSelect">
            <Autocomplete
                value={props.value && props.value}
                id="sourceLanguage"
                size="small"
                defaultValue={props.defaultLanguage && props.defaultLanguage}
                className={clsx(classes.sourceLangSelect, classes.inputWidth, props.redBorder && classes.redBorder)}
                options={sourceLanguage}
                popupIcon={<MKeyboardArrowDownRoundedIcon />}
                getOptionLabel={(option: any) => option.name}
                onOpen={filterSourceLanguage}
                multiple={props.multiple && props.multiple}
                limitTags={1}
                disableClearable={true}
                onChange={(event: any, value: any) => {
                    if(props.customHandleChange){
                        props.customHandleChange();
                    }
                    if(value !== null) {
                        if(props.multiple == 'multiple'){
                            props.setFieldValue('sourceLanguage', value);
                        }else {
                        props.setFieldValue('sourceLanguage', value.code);
                        if(props.setSourceLanguage) {
                            props.setSourceLanguage(value.code)
                        }
                        }
                    } else {
                        props.setFieldValue('sourceLanguage', '');
                        if(props.setSourceLanguage) {
                            props.setSourceLanguage('');
                        }
                    }
                    if(props.sourceLanguageHandler){
                             props.sourceLanguageHandler();
                    }
                }}

                renderInput={params => <TextField {...params}
                                                  label={props.label}
                                                  variant="outlined" color="secondary"/>}
            />
        </div>
        
    )
}