import React, { useEffect, useState } from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { useSelector } from 'react-redux';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  
function MessageAlert(){
    const [open, setOpen] = useState(false);
    const alertData = useSelector((store:any) => store.alert);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setOpen(alertData.showAlertMessage);
    }, [alertData])

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{vertical:'bottom' , horizontal:'left'}}>
            <Alert onClose={handleClose} severity={alertData && alertData.messageType}>
                {alertData && alertData.message}
            </Alert>
        </Snackbar>
    )
}

export default MessageAlert;