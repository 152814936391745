import { RESET_STORE } from '../resetStateAction';

interface IAction {
    type: string,
    payload: any
}

interface IState {
    showSideBar: boolean
}


const SHOW_SIDEBAR = "SHOW_SIDEBAR";

const initialState:IState = {
    showSideBar: true
}

export const sideBarShow = (value:boolean) => {
    return {
        type: SHOW_SIDEBAR,
        payload: value
    }
}

const showSideBar = (state = initialState, action:IAction) => {
    switch (action.type) {
        case SHOW_SIDEBAR:
            return {
                ...state,
                showSideBar: action.payload
            }
        case RESET_STORE:
            return initialState
        default:
            return state
    }
}

export default showSideBar;