import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useStyles from './ProjectTypeSelectStyles'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../store';

interface Language{
  id: number,
  name: string,
  code: string
}

const SOURCE_LANGUAGE_GQL = gql`
  {
    sourceLanguages {
      code
      id
      name
    }
  }
`;

export function ProjectTypeSelect(props:any) {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [targetLanguage, setTargetLanguage] = useState([]);
  const { data } = useQuery(SOURCE_LANGUAGE_GQL,{
    onCompleted: (data:any) => {
      setTargetLanguage(data && data.sourceLanguages)
    },
    onError: ({ graphQLErrors }:any) => {
      dispatch(showAlert({message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message && graphQLErrors[0].message, messageType:'error', showAlertMessage:true}))
    }
  });

  return (
    <div>
      <Autocomplete
        className={classes.inputWidth}
        id="tags-outlined"
        size="small"
        options={targetLanguage}
        getOptionLabel={(option:any) => option.name}
        onChange={(event,value) => {
          const languagesCode:any[] = [];
          value.forEach((languageObj:Language) => {
            languagesCode.push(languageObj.code);
            const newTargetLanguages = data && data.sourceLanguages.filter((element:Language) => element.code !== languageObj.code);
            setTargetLanguage(newTargetLanguages);
          });
          if(value.length < 1){
            setTargetLanguage(data && data.sourceLanguages);
          }
          props.setTargetLanguages(languagesCode);
        }}
        multiple
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            color="secondary"
            label="All Types"
          />
        )}
      />
    </div>
  );
}


