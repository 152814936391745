import React, {useEffect, useState} from 'react'
import useStyles from './SidebarStyles'
import {
    MBox,
    MList,
    MListItem,
    MListSubheader,
    MTypography,
    MListItemAvatar,
    MAvatar,
    MListItemText,
    MInputBase,
    MButton,
    MDivider,
    MListItemIcon,MIconButton
} from '../../../material-ui';
import {MAddIcon, MDashboardOutlinedIcon,MDeleteIcon, MSearchIcon, MSettingsOutlinedIcon} from '../../../material-ui/icons';

import {NavLink, withRouter} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux';
import {gql} from 'apollo-boost';
import {useLazyQuery} from '@apollo/react-hooks';
import {showAlert, selectedProjectId} from '../../../store';
import {FormattedDateParts} from 'react-intl';
import Zoom from '@material-ui/core/Zoom';

const SELECTED_ORGANIZATION_PROJECTS_GQL = gql`
    query Organizations($id: ID){
        organizations(id: $id) {
            projects {
                id
                name
                createdAt
                projectId
            }
        }
    }`;

function Sidebar(props: any) {

    const classes = useStyles({});
    const dispatch = useDispatch();
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const refreshSideBarProjects = useSelector((store: any) => store.projects.refreshSideBarProjects);
    const [projectsData, setProjectsData] = useState();

    const [getProjects] = useLazyQuery(
        SELECTED_ORGANIZATION_PROJECTS_GQL, {
            variables: {id: selectedOrgId},
            fetchPolicy: "no-cache",
            onCompleted: (data: any) => {
                setProjectsData(data && data.organizations[0]);
            },
            onError: ({graphQLErrors}: any) => {
                dispatch(showAlert({
                    message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message && graphQLErrors[0].message,
                    messageType: 'error',
                    showAlertMessage: true
                }))
            }
        } as any);

    useEffect(() => {
        getProjects();
    }, [refreshSideBarProjects, getProjects]);


    const goToSearch = (query: string) => {
        if(query === ""){
            props.history.push('/dashboard');
        }else{
            props.history.push('/search/' + query);
        }
    }


    const goToDetail = (projectId: number, randomProjectId: number) => {
        dispatch(selectedProjectId(projectId));
        props.history.push('/project-detail/' + randomProjectId+'#content');
    }

    const projectsList = (
        <>
            {projectsData && projectsData.projects.slice(0, 5).map((project: any) => (
                <React.Fragment key={project.id}>
                    <MListItem button className={classes.listItem}
                               onClick={() => goToDetail(project.id, project.projectId)}>
                        <MListItemAvatar className={classes.avatarWrap}>
                            <MAvatar variant="circle">
                                {project.name && (project.name.split(' ').length>1) ?
                                    `${project.name.split(' ')[0].charAt(0)}${project.name.split(' ')[1].charAt(0)}`:
                                    `${project.name.charAt(0)}${project.name.charAt(1)}`
                                
                                }
                            </MAvatar>
                        </MListItemAvatar>
                        <MListItemText className={classes.listItemText}
                                       primary={project.name}
                                       secondary={
                                           <FormattedDateParts
                                               value={project.createdAt}
                                               year="numeric"
                                               month="2-digit"
                                               day="2-digit"
                                           >
                                               {parts => (
                                                   <>
                                                       {/*{JSON.stringify(parts)}*/}
                                                       {parts[2].value}
                                                       {parts[1].value}
                                                       {parts[0].value}
                                                       {parts[3].value}
                                                       {parts[4].value}
                                                   </>
                                               )}
                                           </FormattedDateParts>
                                       }/>
                        {/*<FormattedTime value={project.Create project}  day="2-digit"  year="numeric" month="long"/>*/}


                    </MListItem>
                </React.Fragment>
            ))
            }
        </>
    );


    return (
        <Zoom in={props.drawerState} unmountOnExit>
            <MBox className={classes.projectsBox}>
                <MBox px={2} pb={3}>
                    <form>
                        {/* <TextField color="secondary"  size="small" id="outlined-search" label="Search field" type="search" variant="outlined" /> */}
                        <MBox className={classes.search} mt={4}>
                            <div className={classes.searchIcon}>
                                <MSearchIcon/>
                            </div>
                            <MInputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={(e: any) => goToSearch(e.target.value)}
                                inputProps={{'aria-label': 'search'}}
                            />
                        </MBox>
                    </form>
                    <MBox>
                        <MListSubheader className={classes.subheader}>
                            <MBox display="flex" justifyContent="space-between" pt={2}>
                                <MTypography variant="subtitle1" component="span">Recent Projects</MTypography>
                                {(projectsData && projectsData.projects.length > 5) &&
                                <NavLink className={classes.viewAllLink} to="/projects">View All</NavLink>
                                }
                            </MBox>
                        </MListSubheader>
                        {projectsData && projectsData.projects.length > 0 ? projectsList :
                            <p style={{marginLeft: '10px'}}>No projects yet</p>}
                        {(isOwner || isAdmin) && selectedOrgId !== 0 &&
                        <MBox display="flex" justifyContent="center" pt={3}>
                            {window.location.pathname.split("/").pop() !== 'create-project' ?

                                <MButton variant="contained" color="secondary" className={classes.createProjectBtn}
                                         startIcon={<MAddIcon/>}>
                                    <NavLink to="/create-project" style={{color: '#fff', textDecoration: 'none'}}>Create
                                        project</NavLink>
                                </MButton>

                                : ''
                            }

                        </MBox>
                        }
                    </MBox>
                </MBox>
                {selectedOrgId !== 0 &&
                <MBox>
                    <MBox px={2}>
                        <MDivider/>
                        <MDivider/>
                    </MBox>
                    <MBox className="generalLinks" px={2}>
                        <MList>
                            {/* <MListItem button onClick={() => props.history.push('/setting')}>
                                <MListItemIcon><MDashboardOutlinedIcon/></MListItemIcon>
                                <MListItemText primary="Overview"/>
                            </MListItem> */}
                            <MListItem button onClick={() => props.history.push('/setting')}>
                                <MListItemIcon><MSettingsOutlinedIcon/></MListItemIcon>
                                <MListItemText primary="Settings"/>
                            </MListItem>
                        </MList>
                        {/* <MList>
                            <MListItem button>
                                <MListItemIcon><MPermIdentityIcon /></MListItemIcon>
                                <MListItemText primary="Overview" />
                            </MListItem>
                        </MList>
                        <MList>
                            <MListItem button>
                                <MListItemIcon><MFeedbackOutlinedIcon /></MListItemIcon>
                                <MListItemText primary="Overview" />
                            </MListItem>
                        </MList>
                        <MList>
                            <MListItem button>
                                <MListItemIcon><MSettingsOutlinedIcon /></MListItemIcon>
                                <MListItemText primary="Overview" />
                            </MListItem>
                        </MList> */}
                    </MBox>

                </MBox>
                }
            </MBox>
        </Zoom>
    )
}

export default withRouter(Sidebar);