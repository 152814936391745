import React, {useState, useEffect} from 'react';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import useStyles from './OrganizationDropdownStyles';
import {
    MButton,
    MMenu,
    MListItem,
    MAvatar,
    MListItemAvatar,
    MListItemText,
    MBox,
    MTypography,
    MChip
} from '../../../../material-ui';

import {useDispatch, useSelector} from 'react-redux';
import {selectOrganization} from '../../../../store';
import {ListSubheader} from '@material-ui/core';
import {NavLink, withRouter} from 'react-router-dom';

interface Organizations {
    id: number,
    name: string,
    logo: string,
    isOwner: boolean,
    isAdmin: boolean
}

function OrganizationDropdown(props: any) {

    const dispatch = useDispatch();
    const classes = useStyles({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOrg, setSelectedOrg]:any = useState();
    const allOrganizations = useSelector((store:any) => store.orgs.organizations);
    const [organizations, setOrganizations] = useState(allOrganizations && allOrganizations);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectOrg = (organanization: Organizations) => {
        localStorage.setItem('selectedOrgId', String(organanization.id))
        let newOrganizations:Array<Organizations> = organizations;
        // let newOrganizations:Array<any> = organizations;
        newOrganizations.push(selectedOrg);
        setOrganizations(newOrganizations)
        dispatch(selectOrganization(organanization.id));
        setSelectedOrg({
            id: organanization.id,
            name: organanization.name,
            logo: organanization.logo,
            isOwner: organanization.isOwner
        });
        handleClose();
        props.history.push('/dashboard')
    }

    useEffect(() => {
        if(selectedOrg){
            let newOrganizations = allOrganizations.filter((org:Organizations) => org.id !== selectedOrg.id);
            setOrganizations(newOrganizations);
        }
    }, [selectedOrg]);

    useEffect(() => {
        setTimeout(() => {
            if(localStorage.getItem('selectedOrgId')){
                dispatch(selectOrganization(Number(localStorage.getItem('selectedOrgId'))));
                let oldSelectedOrg = organizations.filter((org:Organizations) => org.id === Number(localStorage.getItem('selectedOrgId')));
                setSelectedOrg(oldSelectedOrg[0])
            }else{
                setSelectedOrg(organizations[0])
            }
        }, 500);
    },[])

    useEffect(() => {
        setOrganizations(allOrganizations);
        setSelectedOrg(allOrganizations[0])
    }, [allOrganizations]);

    return (
        <div>
            {allOrganizations && allOrganizations.length > 1 ?
                <MButton color="secondary" className={classes.orgOpener} onClick={handleClick}>
                    {/* <MAvatar className={classes.avatar} variant="circle" src={selectedOrg && selectedOrg.logo}/>&nbsp;&nbsp; */}
                    <MBox className={classes.orgName}
                          px={1}>{selectedOrg && selectedOrg.name}</MBox><ArrowDropDownSharpIcon/>
                    {/* {anchorEl &&  <MBox className={classes.topArrow}>&nbsp;</MBox> } */}
                </MButton> :
                <span className={classes.orgOpener} style={{
                    backgroundColor: '#e5f1f7',
                    padding: 8,
                    borderRadius: 4,
                    color: '#3f74e8',
                    display: 'inline-block',
                    minWidth: 70
                }}>
                    <MBox className={classes.orgName} px={1}>{selectedOrg && selectedOrg.name}</MBox>
                </span>
            }

            {organizations && organizations.length > 0 &&
            <MMenu
                className={classes.avatarDropdown}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                <ListSubheader className={classes.subheader}>
                    <MBox display="flex" justifyContent="space-between" alignItems="center">
                        <MBox pl={1}>
                            <MBox fontSize={16} fontWeight="fontWeightBold" lineHeight={1}>{props.headerText}</MBox>
                        </MBox>
                    </MBox>
                </ListSubheader>

                {organizations && organizations.map((organanization: Organizations) => (
                    <MListItem button className={classes.listItem} key={organanization.id}
                               onClick={() => selectOrg(organanization)}>
                        <MListItemAvatar>
                            {organanization.logo ? (<MAvatar variant="circle" src={organanization.logo}/>) : (
                                <MAvatar variant="circle"
                                         style={{textTransform: 'uppercase'}}>{organanization.name.charAt(0) + '' + organanization.name.charAt(1)}</MAvatar>)}
                        </MListItemAvatar>
                        {/* <MBox>

                        </MBox> */}

                        <MListItemText>
                            <MBox display="flex" alignItems="center">
                                <MBox>
                                    <MTypography>{organanization.name}</MTypography>
                                </MBox>

                                <MBox ml={1}>
                                    {(organanization.isAdmin && !organanization.isOwner ?
                                        <MChip label="admin" className={classes.ownerBadge}> IsAdmin </MChip> : '')}
                                </MBox>

                                <MBox ml={1}>
                                    {(organanization.isOwner ?
                                        <MChip label="owner" className={classes.ownerBadge}> Owner </MChip> : '')}
                                </MBox>
                            </MBox>
                        </MListItemText>
                    </MListItem>
                ))}
                {organizations && organizations.length > 4 ?
                    (<NavLink className={classes.simpleNavLink} to="/all-organization">
                            <MListItem button className={classes.footer}>
                                <MBox variant="body2" fontSize={14} display="block">
                                    <li>See all</li>
                                </MBox>

                            </MListItem></NavLink>
                    ) : ''
                }
            </MMenu>
            }
        </div>
    )
}

export default withRouter(OrganizationDropdown);