import React, {useState, useEffect} from 'react';
import Routing from './routes/routing';
import clsx from 'clsx';
import {Header, Footer, Sidebar, MessageAlert, ReactCustomScrollbars} from './components';
import useStyles from './appStyles';
import {withRouter} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {MBox, MAppBar, MIconButton, MToolbar, MDrawer} from './material-ui';
import {MKeyboardArrowLeftSharpIcon, MKeyboardArrowRightSharpIcon} from './material-ui/icons'
import {ApolloProvider} from '@apollo/react-hooks';
import {client} from './hooks';
import {gql} from 'apollo-boost';
import {getAllOrganizations, selectOrganization, setCurrentUserData} from './store';
import './App.css';


const GET_ORGANIZATIONS_AND_CURRENT_USER_GQL = gql`
{
  currentUser {
    id
    firstName
    lastName
    username
    isShowReport
    profilePicture
    isPM
  }
  organizations {
    id
    name
    logo
    isOwner
    isAdmin
    url
    description
    onlineTranslation
  }
}`;


function App(props: any) {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const isLogin = useSelector((store: any) => store.auth.isLogin);
    const showSideBar = useSelector((store: any) => store.sideBar.showSideBar);
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    // const referer:any = localStorage.getItem('referer') || '/';

    if(!isLogin) {
        // console.log('host',process.env.REACT_APP_HOST);
        // console.log('href',window.location.href);

        if (!isLogin && process.env.REACT_APP_HOST != window.location.href) {
            localStorage.setItem('redirect_url', '/'+window.location.href.replace(process.env.REACT_APP_HOST+'',''));
        }
    }

    useEffect(() => {
        if (isLogin) {
            client
                .query({
                    query: GET_ORGANIZATIONS_AND_CURRENT_USER_GQL,
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                })
                .then((response: any) => {
                    const organizations = response.data.organizations;
                    dispatch(getAllOrganizations(organizations));
                    dispatch(setCurrentUserData(response.data.currentUser));
                    if (localStorage.getItem('selectedOrgId')) {
                        dispatch(selectOrganization(Number(localStorage.getItem('selectedOrgId'))));
                    } else if (organizations && organizations.length > 0) {
                        dispatch(selectOrganization(organizations[0].id));
                    } else {
                        dispatch(selectOrganization(0));
                    }
                });
        }
    }, [isLogin, dispatch]);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const openCloseDrawer = () => {
        if (open === false) {
            handleDrawerOpen();
        } else {
            handleDrawerClose();
        }
    }

    const secureContent = (
        <div className={classes.root}>
            <ApolloProvider client={client}>
                <MAppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [
                            classes.appBarShift]: open
                    })}>
                    <MToolbar>
                        <Header/>
                        {showSideBar &&
                        <MIconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={openCloseDrawer}
                            edge="start"
                            className={clsx(classes.navigationResizer, {
                                [classes.navigationResizerClosed]: !open,
                            })}
                            onMouseEnter={() => {
                                let test = document.getElementById('drawerResizer')!;
                                test.style.opacity = "1";
                            }}
                            onMouseLeave={() => {
                                let test = document.getElementById('drawerResizer')!;
                                test.style.opacity = "0";
                            }}
                            id="drawerResizer"
                        >
                            {(
                                open ? <MKeyboardArrowLeftSharpIcon className={classes.arrowLeft}/> :
                                    <MKeyboardArrowRightSharpIcon className={classes.arrowRight}/>
                            )}
                        </MIconButton>
                        }
                    </MToolbar>
                </MAppBar>

                {selectedOrgId != null && showSideBar &&
                <MDrawer
                    variant="permanent"
                    className={clsx(['mainDrawer'], classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    id="mainDrawer"
                    onMouseEnter={() => {
                        let test = document.getElementById('drawerResizer')!;
                        test.style.opacity = "1";
                    }}
                    onMouseLeave={() => {
                        let test = document.getElementById('drawerResizer')!;
                        test.style.opacity = "0";
                    }}

                >
                    <ReactCustomScrollbars className="scrollBar" autoHide>
                        <Sidebar drawerState={open}/>
                    </ReactCustomScrollbars>
                </MDrawer>
                }
                <MBox component="div" className={classes.content} mt={8} p={5} position="relative">

                    {selectedOrgId != null &&
                    <Routing/>
                    }
                    <Footer/>
                </MBox>
            </ApolloProvider>
        </div>
    );

    return (
        <div className="App">
            {isLogin ? secureContent : <Routing/>}
            <MessageAlert/>
        </div>
    );
}

export default withRouter(App);
