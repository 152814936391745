import { combineReducers } from "redux";
import authReducer from "./auth/AuthReducer";
import organizationsReducer from "./organizations/OrganizationsReducer";
import showSideBar from "./show-sidebar/ShowSideBarReducer";
import showAlertMessage from "./show-alert-message/ShowAlertMessage";
import projectsReducer from "./projects/ProjectsReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    orgs: organizationsReducer,
    sideBar: showSideBar,
    alert: showAlertMessage,
    projects: projectsReducer
})

export default rootReducer;