import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar:{
            '&.MuiAvatar-root':{
                width: '28px',
                height: '28px',
                border: '1px solid #d8d9db',
            }
        },
        avatarDropdown:{
            '& ul':{
                padding: 0,
            },
            '& h6':{
                lineHeight: 'normal',
            },
        },
        orgName:{
            whiteSpace: 'nowrap',
        },
        orgOpener:{
            backgroundColor: '#e5f1f7',
        },
        viewAllLink:{
            color: theme.palette.secondary.main,
            lineHeight: 2,
            textDecoration: 'none',
        },
        subheader: {
            backgroundColor: theme.palette.background.paper,
            borderBottom: '1px solid #eee',
            padding: theme.spacing(2,3),
            '& div div div':{
                position: 'relative',
            },
        },
        topArrow:{
            position: 'absolute',
            bottom: '0',
            right: 1,
            width: 0,
            height: 0,
            zIndex: 9999999999,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '11px solid #f1e7e7',
        },
        listItem:{
            maxWidth:350,
            minWidth:350,
            padding: theme.spacing(2),
            borderBottom: '1px solid #eee',
            '& img':{
                objectFit:'contain'
            }
        },
        footer:{
            padding: '20px 24px',
            backgroundColor: '#fafafa',
            '& div':{
                width: '100%',
                textAlign: 'center',
                textTransform: 'uppercase',
            },
        },
        simpleNavLink:{
            color: "#3a3a3a",
            textDecoration: "none", 
        },
        ownerBadge: {
            '&.MuiChip-root':{
                height: 24,
                fontSize: '12px',
                backgroundColor: '#ebeff2',
                borderRadius: 2,
                textTransform: 'capitalize',
            }
        }
    }),
);

export default useStyles;