import React, {useEffect, useState} from 'react';
import useStyles from './TargetLanguageSelectStyles'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../store';
import { MAutoComplete } from '../../material-ui';


interface Language{
  id: number,
  name: string,
  code: string
}

const SOURCE_LANGUAGE_GQL = gql`
  {
    sourceLanguages {
      code
      id
      name
    }
  }
`;

export function TargetLanguageSelect(props:any) {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [targetLanguage, setTargetLanguage] = useState([]);

  const { data } = useQuery(SOURCE_LANGUAGE_GQL,{
    onCompleted: (data:any) => {
        if(props.createProject && props.createProject == 'createProject'){
        let ary:any= [];
        let aryT:any= [];
        let ind = findWithAttr(data && data.sourceLanguages, 'code', 'en-US');
        ary.push(data && data.sourceLanguages && data.sourceLanguages[ind].name);
            aryT.push(data && data.sourceLanguages && data.sourceLanguages[ind].code);
        setTargetLanguage(ary);
        props.setTargetLanguages(aryT)
    }else{
        setTargetLanguage(data && data.sourceLanguages && data.sourceLanguages);

        }

    },
    onError: ({ graphQLErrors }:any) => {
      dispatch(showAlert({message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message && graphQLErrors[0].message, messageType:'error', showAlertMessage:true}))
    }
  });

    function findWithAttr(array: any, attr: any, value: any) {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
  const filterTargetLanguage = (allTargetLanguage: Array<Language>) => {
    let newTargetLanguages:any = allTargetLanguage;
    if(props.filterLanguages.length > 0){
      props.filterLanguages.forEach((targetLanguageCode:any) => {
        newTargetLanguages = newTargetLanguages.filter((element:any) => element.code !== targetLanguageCode);
      });
      setTargetLanguage(newTargetLanguages);
    }else{
      setTargetLanguage(newTargetLanguages);
    }
  }

  return (
    <div>
        {props.createProject && props.createProject == 'createProject' ?
            <MAutoComplete
                className={classes.inputWidth}
                id="tags-outlined"
                name="targetLanguage"
                options={targetLanguage}
                defaultValue={targetLanguage}
                disabled={true}
                getOptionLabel={(option:any) => option.name}
                label={targetLanguage.join(',')}
            />:
            <MAutoComplete
                className={classes.inputWidth}
                id="tags-outlined"
                name="targetLanguage"
                options={targetLanguage}
                getOptionLabel={(option:any) => option.name}
                onOpen={() => filterTargetLanguage(data && data.sourceLanguages)}
                onChange={(event:any,value:any) => {
                  const languagesCode:any[] = [];
                  let newTargetLanguages = data && data.sourceLanguages;
                  value.forEach((languageObj:Language) => {
                    languagesCode.push(languageObj.code);
                    newTargetLanguages.filter((element:Language) => element.code !== languageObj.code);
                  });
                  props.setTargetLanguages(languagesCode);
                  filterTargetLanguage(newTargetLanguages)
                  if(value.length < 1){
                    filterTargetLanguage(data && data.sourceLanguages)
                  }
                }}
                multiple
                filterSelectedOptions
                label={targetLanguage.join(',')}
            />
        }

    </div>
  );
}


