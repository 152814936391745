import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWidth:{
      width: 493,
    },
    teamSelect:{
      "&.MuiSelect-outlined":{
        borderColor: theme.palette.secondary.main,
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px 15px'
      },
      '& .MuiInputLabel-formControl':{
        top: '-7px'
      },
      '& .MuiInputLabel-shrink':{
        top: '0px'
      },
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      },
    },
    teamSelectLabel:{
      backgroundColor: '#fff',
      padding: '0 5px',
    },
    redBorder:{
      "& fieldset":{
        borderColor: '#f44336 !important'
      }
    }
  }),
);

export default useStyles;