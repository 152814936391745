import { SET_AUTH, SET_CURRENT_USER_DATA } from "./AuthActionTypes";

interface currentUser{
    id: number,
    username: string,
    firstName: string,
    lastName: string
}

export const setAuth = (token:string) => {
    return{
        type: SET_AUTH,
        payload: token
    }
}

export const setCurrentUserData = (data:currentUser) => {
    return{
        type: SET_CURRENT_USER_DATA,
        payload: data
    }
}