import {REFRESH_SIDEBAR_PROJECTS, SELECTED_PROJECT_ID, SET_USER_ROLES,
    SET_PROJECT_EVENT_SOURCE, SET_PROJECT_FILE_EVENT_SOURCE} from "./ProjectsActionTypes";
import {RESET_STORE} from '../resetStateAction';

interface IAction {
    type: string,
    payload: any
}

interface IState {
    refreshSideBarProjects: boolean,
    selectedProjectId: number,
    userRoles: [],
    projectEventSource: any,
    projectFileEventSource: any,
}

const initialState: IState = {
    refreshSideBarProjects: false,
    selectedProjectId: 0,
    userRoles: [],
    projectEventSource: false,
    projectFileEventSource: false,
};

const projectsReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case REFRESH_SIDEBAR_PROJECTS:
            return {
                ...state,
                refreshSideBarProjects: !state.refreshSideBarProjects
            };
        case SELECTED_PROJECT_ID:
            return {
                ...state,
                selectedProjectId: action.payload
            }
        case SET_USER_ROLES:
            return {
                ...state,
                userRoles: action.payload
            }
        case SET_PROJECT_EVENT_SOURCE:
            return {
                ...state,
                projectEventSource: action.payload
            }
        case SET_PROJECT_FILE_EVENT_SOURCE:
            return {
                ...state,
                projectFileEventSource: action.payload
            }
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default projectsReducer;