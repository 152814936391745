import React, {ComponentType, SFC, useEffect} from 'react';
import {Route, Redirect, withRouter} from 'react-router-dom';
import {useSelector} from 'react-redux';


const PrivateRoute: SFC<any> = (
    {props: any,component: Component, ...rest}:
        any
) => {

    const isLogin = useSelector((store: any) => store.auth.isLogin);
    return (
        <Route
            {...rest}
            render={componentProps =>
                isLogin ? (
                    <Component {...componentProps} />
                ) : (
                    <Redirect
                        to={{pathname: "/"}}
                    />
                )
            }
        />
    );
}

export default withRouter(PrivateRoute);