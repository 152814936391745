import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldActionButtons:{
      textAlign: 'right',
      '& button': {
        width: 32,
        height: 32,
        backgroundColor: 'rgb(245, 246, 247)',
        color:'#3a3a3a',
        padding: 0,
        boxShadow: '0px 3px 9px 0px rgba(160, 165, 185, 0.26)',
        border: '1px solid rgb(228, 229, 232)',
        borderRadius: 5,
      }
    },
    inputWrappper:{
      verticalAlign: 'middle',
    },
    input:{
        width: 'auto',
        height: 30,
        transition:'all .3s',
        border:'1px solid #d9d9d9',
        borderRadius:4,
        '&:hover':{
            backgroundColor: 'rgb(235, 236, 240)'
        },
        '& fieldset':{
            border:'none',
            padding:0
        },
        '& input':{
            padding: 5,
        }
    },
    editButtons:{
      position: 'absolute',
      right: '25px',
      bottom: '20px'
    }
  }),
);

export default useStyles;