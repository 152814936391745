import {GET_ALL_ORGANIZATIONS, SELECT_ORGANIZATION} from "./OrganizationsActionTypes";
import {RESET_STORE} from '../resetStateAction';

interface IAction {
    type: string,
    payload: any
}

interface IState {
    organizations: any,
    selectedOrgId: any,
    isOwner: boolean,
    isAdmin: boolean
}

const initialState: IState = {
    organizations: [],
    selectedOrgId: null,
    isOwner: false,
    isAdmin: false
};

const checkSelectedOrgOwner = (selectedOrgId: number, organizations: any) => {
    // const selectedOrg = organizations.filter((org: any) => org !== selectedOrgId);
    const selectedOrg = organizations.find((org: any) => {
        return org.id === selectedOrgId
    });
    return (selectedOrg) ? selectedOrg.isOwner : false;
};

const checkSelectedOrgAdmin = (selectedOrgId: number, organizations: any) => {
    // const selectedOrg = organizations.filter((org: any) => org !== selectedOrgId);
    const selectedOrg = organizations.find((org: any) => {
        return org.id === selectedOrgId
    });
    return (selectedOrg) ? selectedOrg.isAdmin : false;
};

const organizationsReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_ALL_ORGANIZATIONS:
            return {
                ...state,
                organizations: action.payload
            };
        case SELECT_ORGANIZATION:
            return {
                ...state,
                isOwner: checkSelectedOrgOwner(action.payload, state.organizations),
                isAdmin: checkSelectedOrgAdmin(action.payload, state.organizations),
                selectedOrgId: action.payload
            };
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default organizationsReducer;