import { REFRESH_SIDEBAR_PROJECTS, SELECTED_PROJECT_ID, SET_USER_ROLES,
    SET_PROJECT_EVENT_SOURCE, SET_PROJECT_FILE_EVENT_SOURCE } from "./ProjectsActionTypes"

export const refreshSideBarProjects = () => {
    return {
        type: REFRESH_SIDEBAR_PROJECTS
    }
}

export const selectedProjectId = (id:number) => {
    return {
        type: SELECTED_PROJECT_ID,
        payload: id
    }
}

export const setUserRoles = (userRoles:any) => {
    return {
        type: SET_USER_ROLES,
        payload: userRoles
    }
}

export const setProjectEventSource = (data: EventSource) => {
    return {
        type: SET_PROJECT_EVENT_SOURCE,
        payload: data
    }
};

export const setProjectFileEventSource = (data: EventSource) => {
    return {
        type: SET_PROJECT_FILE_EVENT_SOURCE,
        payload: data
    }
};
