import {InMemoryCache, ApolloClient} from "apollo-boost";
import {createUploadLink} from 'apollo-upload-client'
import {ApolloLink, concat} from 'apollo-link';


const httpLink = createUploadLink({
    // uri: 'https://ent.marstranslation.com/graphiql/',
    uri: process.env.REACT_APP_API_END_POINT + '/graphiql/',
    // uri: 'http://local.mtenterprise.com/graphiql/',
});

// localStorage.removeItem('access_token');
const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            "keep-alive": "true",
            "accept": "application/json",
            "content-type": 'application/json',
            "authorization": 'Bearer ' + localStorage.getItem('access_token')
        }
    });

    return forward(operation);
});

export const client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache({
        addTypename: false,
    })
});