import React , { Suspense, lazy } from 'react';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Route , Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuth } from '../store';

const Dashboard = lazy(() => import('../screens/dashboard/Dashboard'));
const CreateProject = lazy(() => import('../screens/create-project/CreateProject'));
const ProjectDetail = lazy(() => import('../screens/project-detail/ProjectDetail'));
const ProjectSearch = lazy(() => import('../components/partials/sidebar/project-search/ProjectSearch'));
const CreateOrganization = lazy(() => import('../screens/create-organization/CreateOrganization'));
const Subscription = lazy(() => import('../screens/subscription/Subscription'));
const Login = lazy(() => import('../screens/login/Login'));
const Signup = lazy(() => import('../screens/signup/Signup'));
const Team = lazy(() => import('../screens/team/Team'));
const Setting = lazy(()=> import('../screens/setting/Setting'));
const TMXGroup = lazy(()=> import('../screens/tmx-group/TmxGroup'));
const Projects = lazy(()=> import('../screens/projects/Projects'));
const CapacityHours = lazy(()=> import('../screens/capacity/Projects'));
const OrganizationList = lazy(()=> import('../screens/organization-list/Organizations'));
const MyAccount = lazy(() => import('../screens/my-account/MyAccount'));
const Page404 = lazy(() => import('../screens/page-404/Page404'));
const DownloadFile = lazy(() => import('../screens/file-download/DownloadFile'));

const progressStyle:any = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
}

function Routing(){
    const dispatch = useDispatch();
    const token = localStorage.getItem('access_token');
    if(token){
        dispatch(setAuth(token));
    }
    
    return(
        <Suspense fallback={<LinearProgress color="secondary" style={progressStyle}/>}>
            <Switch>
                <PublicRoute exact path="/" component={Login} />
                <PublicRoute exact path="/signup" component={Signup} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/my-account" component={MyAccount} />
                <PrivateRoute exact path="/create-project" component={CreateProject} />
                <PrivateRoute exact path="/create-organization" component={CreateOrganization} />
                <PrivateRoute exact path="/project-detail/:id" component={ProjectDetail} />
                <PrivateRoute exact path="/search/:query" component={ProjectSearch} />
                <PrivateRoute exact path="/subscription" component={Subscription} />
                <PrivateRoute exact path="/setting" component={Setting} />
                <PrivateRoute exact path="/projects" component={Projects} />
                <PrivateRoute exact path="/capacity/report" component={CapacityHours} />
                <PrivateRoute exact path="/tmx-group" component={TMXGroup} />
                <PrivateRoute exact path="/teams" component={Team} />
                <PrivateRoute exact path="/all-organization" component={OrganizationList} />
                <PrivateRoute exact path="/file/download/:id/:type" component={DownloadFile} />
                <Route exact path="/page-not-found" component={Page404} />
                <Route exact path="*" >
                    <Redirect to='/page-not-found'/>
                </Route>
            </Switch>
        </Suspense>
    )

}

export default Routing;