import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWidth:{
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        minWidth: 300,
      },
    },
    instructions:{
      color: '#a0a5b9',
    },
    sourceLangSelect:{
      "&.MuiSelect-outlined":{
        borderColor: theme.palette.secondary.main,
      }
    },
  }),
);

export default useStyles;