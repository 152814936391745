import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import useStyles from './AutoCompletetStyles';
import TextField from '@material-ui/core/TextField';
import {MKeyboardArrowDownRoundedIcon} from "../../material-ui/icons";

interface iProps{
    label: string,
    name?: string,
    className?: string,
    id: string,
    options: Array<any>,
    getOptionLabel: any,
    onOpen?: any,
    onChange?: any,
    onInputChange?: any,
    multiple?: any,
    disabled?: any,
    filterSelectedOptions?:any,
    defaultValue?:any,
    disableClearable?:boolean,
    autoComplete?:any,
    popupIcon?:any,
    clearOnEscape?:any,
    limitTags?:number,
    style?:any,
    value?:any,
    renderOption?:any,
    disableCloseOnSelect?:any,
    loading?:boolean,
    loadingText?:string
}
//
// interface iProps{
//   label: string,
//   name: string,
//   className: string,
//   id: string,
//   options: Array<string>,
//   getOptionLabel: any,
//   onOpen: any,
//   onChange: any,
//   multiple: any,
//   filterSelectedOptions:any,
//   style?: any,
//   defaultValue?: any,
//   disableClearable?: any
//
// }

export function MAutoComplete(props:iProps){
  return (
    <Autocomplete
    size="small"
    popupIcon={<MKeyboardArrowDownRoundedIcon />}
    {...props}
      renderInput={(params) => <TextField  {...params} color="secondary" label={props.label} variant="outlined" />}
    />
  )
}