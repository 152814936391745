interface IAction {
    type: string,
    payload: any
}

interface IState {
    showAlertMessage: boolean,
    message: string,
    messageType: string
}


const SHOW_ALERT_MESSAGE = "SHOW_ALERT_MESSAGE";

const initialState:IState = {
    showAlertMessage: false,
    message: '',
    messageType: 'error'
}

export const showAlert = (alertData:IState) => {
    return {
        type: SHOW_ALERT_MESSAGE,
        payload: alertData
    }
}

const showAlertMessage = (state = initialState, action:IAction) => {
    switch (action.type) {
        case SHOW_ALERT_MESSAGE:
            return {
                ...state,
                showAlertMessage: action.payload.showAlertMessage,
                message: action.payload.message,
                messageType: action.payload.messageType
            }
        default:
            return state
    }
}

export default showAlertMessage;