import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    projectsBox:{
      paddingTop: theme.spacing(8)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      border: '2px solid #cfd3db',
      width: '100%',
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      },
    },
    searchIcon: {
      position: 'absolute',
      right: '8px',
      top: '8px',
      pointerEvents: 'none',
      '& svg':{
        fontSize: '20px',
        color: '#6f7b8f',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1,4,1,1),
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
        paddingRight: 0,
        paddingLeft: 0,
    },
    listItem:{
        // maxWidth:350,
        // minWidth:350,
        padding: theme.spacing(1, 1),
        "&:hover .MuiAvatar-root":{
          backgroundColor: theme.palette.secondary.main,
          color: "#fff",
        },
        "&:hover .MuiListItemText-root span":{
          color: theme.palette.secondary.main,
        },
    },
    listItemText:{
      margin: 0,
      width: 110,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '& span':{
        fontSize: 14,
        textTransform: "capitalize",
      },
    },
    viewAllLink:{
      color: theme.palette.secondary.main,
      lineHeight: 2,
      textDecoration: 'none',
    },
    avatarWrap:{
      "& .MuiAvatar-root":{
        transition: "ease .3s",
        fontSize: 14,
        textTransform: 'uppercase',
        backgroundColor: "#e6edfd",
        color: "#2c2c2c",
      },
    },
    footer:{
        textAlign: 'center',
        padding: theme.spacing(2,3),
        backgroundColor: '#fafafa',
    },
    createProjectBtn:{
      minWidth: 196,
    },
  }),
);

export default useStyles;