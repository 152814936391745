import React, {useState, MouseEvent} from 'react'
import Fade from '@material-ui/core/Fade';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from './HeaderStyles';
import OrganizationDropdown from './organization-dropdown/OrganizationDropdown';
import {MGrid, MIconButton, MBox, MMenu, MButton, MAvatar, MBadge} from "../../../material-ui";
import {NavLink, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {resetState} from '../../../store/resetStateAction';
import {showAlert} from '../../../store';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';


function Header(props: any) {

    const classes = useStyles({});
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const organizations = useSelector((store: any) => store.orgs.organizations);
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const isOwner = useSelector((store: any) => store.orgs.isOwner);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        dispatch(showAlert({message: 'Logout sucessfully.', messageType: 'info', showAlertMessage: true}))
        localStorage.clear()
        dispatch(resetState());
        props.history.push('/')
    }

    return (
        // Menu and right icons
        <MGrid container alignItems="center">

            <MGrid item sm={7}>
                <MGrid container alignItems="center">
                    <MGrid item className={classes.logoBox}>
                        <NavLink to="/">
                            <img src="/assets/images/ccjk-logo.png" alt="logo" width="108px"/>
                        </NavLink>
                    </MGrid>
                    {
                        organizations && organizations.length > 0 &&
                        <MGrid item alignItems="center">
                            <MBox className={classes.mainNav} component="ul" display="flex" p={0}>
                                {/*/!* <li>*/}
                                {/*<ProjectsDropdown titleText="Projects" headerText="Recent Projects" footerText="View All Projects" />*/}
                                {/*</li> *!/*/}
                                <li><NavLink className={classes.menuLink} to="/dashboard">Dashboard</NavLink></li>
                                {isAdmin || isOwner ?
                                    <li><NavLink className={classes.menuLink} to="/teams">Teams</NavLink></li>
                                    : ""}
                                {currentUser && currentUser.isShowReport == 1 ?
                                <li><NavLink className={classes.menuLink} to="/capacity/report">Capacity report</NavLink></li>:''}
                                {/* <li><NavLink className={classes.menuLink} to="/">Reports</NavLink></li> */}
                                {/* <li><NavLink className={classes.menuLink} to="/">Orders</NavLink></li> */}
                                {/* <li><NavLink className={classes.menuLink} to="/">Dashboard</NavLink></li> */}
                            </MBox>
                        </MGrid>
                    }
                </MGrid>
            </MGrid>

            <MGrid item sm={5} container justify="flex-end">
                <MBox display="flex" flexDirection="row" p={1} alignItems="center">
                    <MBox>
                        {(organizations && organizations.length < 1) && (window.location.href.split('/').pop() === 'dashboard') ?
                            <MButton variant="contained" color="secondary">
                                <NavLink to="/subscription"
                                         style={{color: '#fff', textDecoration: 'none'}}>Subscribe</NavLink>
                            </MButton> : ""
                        }
                        {/* {localStorage.getItem('subscription') === "false" &&
                <MButton variant="contained" color="secondary" onClick={() => localStorage.setItem('subscription', "true")}>
                  <NavLink to="/subscription" style={{color:'#fff',textDecoration:'none'}}>Subscribe</NavLink>
                </MButton>
              } */}
                        {organizations && organizations.length > 0 &&
                        <MBox mr={2}>
                            <OrganizationDropdown headerText="Organizations" footerText="See All"/>
                        </MBox>
                        }
                    </MBox>
                    <MBox>
                        <MIconButton aria-label="Help icon">
                            <HelpIcon className={classes.headerIcon}></HelpIcon>
                        </MIconButton>
                    </MBox>
                    <MBox>
                        <MIconButton aria-label="settings">
                            <SettingsIcon className={classes.headerIcon}/>
                        </MIconButton>
                    </MBox>
                    <MBox>
                        <MIconButton aria-label="show 17 new notifications" aria-controls="notificationMenu"
                                     aria-haspopup="true">
                            {/* <MBadge badgeContent={1} color="secondary" > */}

                            <MBadge variant="dot" color="secondary" className={classes.dotBagde}>
                                <NotificationsIcon className={classes.headerIcon}/>
                            </MBadge>
                        </MIconButton>
                    </MBox>
                    {/* <MBox>
              <MIconButton edge="end" aria-label="account of current user" aria-haspopup="true" color="inherit">
                <MAvatar className={classes.small} alt="Remy Sharp" src="/assets/images/avatar.jpg" />
              </MIconButton>
            </MBox> */}
                    <MBox pl={1}>
                        <MIconButton aria-controls="notificationMenu" aria-haspopup="true" color="inherit"
                                     onClick={handleClick} className={classes.userAvatarWrapper}>
                            {/* <AccountCircle /> */}
                            <MAvatar variant="circle" alt="Remy Sharp"
                                     src={currentUser && currentUser.profilePicture ? currentUser.profilePicture : "/assets/images/avatar.jpg"}/>
                        </MIconButton>

                        <MMenu
                            id="notificationMenu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                            className={classes.userDropdown}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {/* <MMenuItem onClick={handleClose}>Home</MMenuItem> */}
                            {/* <MMenuItem onClick={handleClose}>Profile</MMenuItem> */}
                            {/*<MMenuItem onClick={handleClose}>My account</MMenuItem> */}
                            <MBox p={1} pb={2} textAlign="center" className={classes.avatarBox}>
                                <MAvatar variant="circle" alt="Remy Sharp"
                                         src={currentUser && currentUser.profilePicture ? currentUser.profilePicture : "/assets/images/avatar.jpg"}/>
                                <MBox>{currentUser && currentUser.firstName + ' ' + currentUser.lastName}</MBox>
                            </MBox>
                            <MenuItem onClick={handleClose}><NavLink className={classes.menuLink}
                                                                     to="/dashboard">Dashboard</NavLink></MenuItem>
                            <MenuItem onClick={handleClose}><NavLink className={classes.menuLink} to="/my-account">My
                                Account</NavLink></MenuItem>
                            <MenuItem onClick={logout} className={`${classes.logoutLink}`}><span
                                className={`${classes.menuLink} ${classes.logoutLink}`}>Logout</span></MenuItem>

                        </MMenu>
                    </MBox>
                </MBox>
            </MGrid>
        </MGrid>
    );
}

export default withRouter(Header);