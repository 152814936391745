import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MBox } from '../../material-ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMsg:{
        fontSize: 12,
        color:'#f44336',
        position: 'absolute',
        left: 0,
        margin: 0,
        bottom: '-16px',
    },
  }),
);

export function FormErrorMessage (props:any){
    const classes = useStyles();

    return (
        <MBox className={classes.errorMsg} component="span" {...props}>{props.message}</MBox>
    )
}